import Vue from "vue";
import Router from "vue-router";
import GoogleAuthService from "./services/GoogleAuthService"

Vue.use(Router);

function guardMyRoute(to, from, next){ 
        let token = Vue.cookie.get("session-token");
        GoogleAuthService.verify(JSON.stringify({ token: token})).then(
          (response) => {
            if (response.data == null) {
              next("/")
            } else {
              next();
            }

          }
        );

}

export default new Router ({
    mode: "history",
    routes: [
        {
            path: "/droplets",
            name: "VMList",
            beforeEnter: guardMyRoute,
            component: () => import("./components/VMList")
        },
        {
            path: "/landing",
            name: "Landing",
            beforeEnter: guardMyRoute,
            component: () => import ("./components/Landing")
        },
        {
            path: "/",
            name: "Login",
            component: () => import ("./components/LoginNew")
        },

        {
            path: "/metadata/:selector",
            name: "Metadata", 
            beforeEnter: guardMyRoute,
            component: () => import("./components/MetaDataTable")
        },
        {
          path: "/campaigns/:id",
          name: "Campaign", 
          beforeEnter: guardMyRoute,
          component: () => import("./components/DetailViewCampaign")
        },
        {
          path: "/profile_owners/:id",
          name: "Profile Owner", 
          beforeEnter: guardMyRoute,
          component: () => import("./components/DetailViewProfileOwner")
        },
        {
          path: "/admin",
          name: "Admin", 
          beforeEnter: guardMyRoute,
          component: () => import("./components/UAMDashboard")
        },
        
       
    ]
});